import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  errorText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main,
    fontSize: '4rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  messageText: {
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
    textAlign: 'center',
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        <Typography variant="h1" className={classes.errorText}>
          404
        </Typography>
        <Typography variant="h5" className={classes.messageText}>
          Oops! The page you're looking for does not exist.
        </Typography>
      </div>
  );
};

export default NotFoundPage;
