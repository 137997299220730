import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Settings from "../../settings/settings.json";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: Settings.colors.primary,
    "&:hover": {
      fill: Settings.colors.secondary,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svgHover}
      version="1.1"
      width="40px"
      height="40px"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 40 40"
    >
      <g>
        <path style={{ opacity: 1 }} d="M-0.017 -0.017h40v40H-0.017V-0.017Z" />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M5.45 15.617h1.8a160.604 160.604 0 0 0 -0.45 2.3c0.604 -0.744 1.343 -0.916 2.217 -0.517 0.169 0.133 0.275 0.305 0.317 0.517a3.015 3.015 0 0 1 0 0.633 119.338 119.338 0 0 0 -0.85 4.2h-1.833c0.25 -1.234 0.5 -2.467 0.75 -3.7 0.062 -0.462 -0.133 -0.612 -0.583 -0.45 -0.131 0.138 -0.225 0.3 -0.283 0.483a215.622 215.622 0 0 1 -0.75 3.667h-1.8a406.134 406.134 0 0 1 1.467 -7.133Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M26.25 15.617h1.8l-0.267 1.333h-1.8a21.035 21.035 0 0 1 0.267 -1.333Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M15.883 15.617h1.8c-0.488 2.375 -0.977 4.753 -1.467 7.133 -0.611 0.011 -1.223 0 -1.833 -0.033a2009.567 2009.567 0 0 0 1.5 -7.1Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M20.883 17.183c0.373 -0.035 0.729 0.026 1.067 0.183 0.186 0.167 0.308 0.372 0.367 0.617 0.516 -0.685 1.194 -0.924 2.033 -0.717 0.323 0.07 0.529 0.264 0.617 0.583 0.022 0.233 0.022 0.467 0 0.7a155.957 155.957 0 0 0 -0.85 4.2h-1.833c0.25 -1.233 0.5 -2.466 0.75 -3.7 0.108 -0.33 -0.009 -0.497 -0.35 -0.5 -0.258 0.03 -0.419 0.175 -0.483 0.433a167.206 167.206 0 0 0 -0.783 3.767h-1.8a494.273 494.273 0 0 1 0.767 -3.867c-0.012 -0.22 -0.128 -0.331 -0.35 -0.333 -0.194 -0.009 -0.338 0.069 -0.433 0.233a1.919 1.919 0 0 1 -0.1 0.267 162.142 162.142 0 0 0 -0.75 3.7h-1.833c0.367 -1.801 0.734 -3.601 1.1 -5.4h1.767a3.317 3.317 0 0 0 -0.083 0.567 5.26 5.26 0 0 1 0.517 -0.483c0.216 -0.114 0.438 -0.197 0.667 -0.25Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#8bb4c9"
          d="M12.983 17.35a24.318 24.318 0 0 1 1.8 -0.033c0.009 0.064 -0.002 0.119 -0.033 0.167v-0.133h-1.767Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M25.883 17.35h1.8a358.13 358.13 0 0 1 -1.1 5.4h-1.8a468.974 468.974 0 0 1 1.1 -5.4Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M12.983 17.35h1.767v0.133a368.033 368.033 0 0 0 -1.067 5.267h-1.767a5.526 5.526 0 0 0 0.083 -0.6c-0.263 0.385 -0.624 0.624 -1.083 0.717 -0.267 0.022 -0.533 0.022 -0.8 0 -0.497 -0.121 -0.741 -0.438 -0.733 -0.95 0.011 -0.156 0.028 -0.312 0.05 -0.467a783.118 783.118 0 0 1 0.85 -4.067c0.6 -0.033 1.2 -0.044 1.8 -0.033a684.75 684.75 0 0 0 -0.767 3.833c-0.001 0.237 0.115 0.36 0.35 0.367 0.296 -0.04 0.474 -0.207 0.533 -0.5a217.343 217.343 0 0 1 0.783 -3.7Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#fdfefe"
          d="M32.95 20.15c-0.046 0.26 -0.063 0.527 -0.05 0.8a1.981 1.981 0 0 0 0.117 -0.333 198.803 198.803 0 0 0 1.133 -3.233 16.827 16.827 0 0 1 1.833 -0.017 417.004 417.004 0 0 0 -2.583 5.517 5.115 5.115 0 0 1 -0.467 0.833c-0.335 0.454 -0.785 0.715 -1.35 0.783a9.62 9.62 0 0 1 -1.333 -0.017c0.078 -0.423 0.156 -0.845 0.233 -1.267 0.316 0.07 0.616 0.031 0.9 -0.117l0.183 -0.183a18.891 18.891 0 0 0 -0.067 -1.9 145.17 145.17 0 0 1 -0.267 -3.467l-0.033 -0.067a30.212 30.212 0 0 0 -0.217 1.067h-0.767v0.1a809.623 809.623 0 0 1 -0.833 4.1h-1.8c0.277 -1.399 0.555 -2.799 0.833 -4.2h-0.6a33.843 33.843 0 0 1 0.233 -1.2h0.633c0.048 -0.48 0.198 -0.924 0.45 -1.333 0.32 -0.34 0.714 -0.524 1.183 -0.55a5.936 5.936 0 0 1 1.267 0.05l-0.2 1.133c-0.587 -0.129 -0.876 0.104 -0.867 0.7h2.567a216.261 216.261 0 0 0 -0.133 2.8Z"
        />
      </g>
    </svg>
  );
};
