import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import { Home } from "../pages/Home";
import NotFoundPage from "../pages/NotFoundPage";

export const App = () => {
    return (
        <ThemeProvider>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </Router>
        </ThemeProvider>
    );
};
