import React, { useState } from "react";
import {
  Typography,
  Container,
  Button,
  Collapse,
  Box,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import OurWorks from "./OurWorks";
import WhatWeDo from "./WhatWeDo";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingTop: theme.spacing(12),
    maxHeight: "100%",
    overflow: "auto",
  },
  "@keyframes Reveal": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  button: {
    backgroundColor: "transparent",
    color: theme.palette.foreground.default,
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  subHeading: {
    maxWidth: "100%",
    textWrap: "wrap",
  },
  revealText: {
    animation: `$Reveal 1.5s ${theme.transitions.easing.easeInOut}`,
  },
  divider: {
    margin: "8px 0",
  },
}));

const ShowMoreContent = () => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <Box mb={2}>
      <Collapse in={showMore} timeout="auto" unmountOnExit>
        <Box mb={2}>
          <Typography variant="body1" className={classes.revealText}>
            Our aim is to provide smarter, data-driven, and user-centric
            solutions that help individuals turn their dreams into reality. Our
            focus is on creating innovative products that empower people to
            achieve their goals with greater ease and efficiency.
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <WhatWeDo />
        <Divider className={classes.divider} />
        <OurWorks />
      </Collapse>
      {!showMore && (
        <Box my={3} textAlign={"center"}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleShowMore}
          >
            <TextDecrypt text="Know More" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ShowMoreContent;

export const Content = () => {
  const classes = useStyles();

  return (
    <Container component="main" className={`${classes.main}`} maxWidth="md">
      <Box mb={2}>
        <Typography variant="h2" component="h1" gutterBottom>
          <TextDecrypt text="Hey, we're Hulmify!" />
        </Typography>
        <Typography
          className={`${classes.subHeading}`}
          variant="h5"
          component="h2"
          gutterBottom
        >
          <TextDecrypt text="Hulmify" /> comes from an Arabic word called{" "}
          <TextDecrypt text="حلم (hulm), meaning dream" /> and we're all about
          turning dreams into reality.
        </Typography>
      </Box>
      <ShowMoreContent />
    </Container>
  );
};
